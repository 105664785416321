<template>
  <b-container fluid style="width: 100%">
    <!-- Navbar :: start -->
    <b-row v-if="!this.$cookies.get('company_id')">
      <b-col col md="12">
        <NavbarCompNew />
      </b-col>
    </b-row>
    <MainHeader v-if="this.$cookies.get('company_id')" />
    <!-- Navbar :: end -->

    <!-- enablingJobs :: start  -->
    <b-row class="py-5 banner-img mb-5">
      <!-- leftSection :: start -->
      <b-col col sm="12" md="7" class="py-5">
        <div class="d-flex justify-content-start align-items-start px-4 mx-auto">
          <span class="d-none d-md-block font-weight-semibold text-justified"
            style="color: white; font-size: clamp(2rem, 5vw, 4rem)">{{ $gettext("Enabling_jobs_for_millions") }}</span>
          <span class="d-block d-sm-none font-weight-semibold text-center"
            style="color: white; font-size: clamp(2rem, 5vw, 4rem)">{{ $gettext("Enabling_jobs_for_millions") }}</span>
        </div>
      </b-col>
      <!-- leftSection :: end -->

      <!-- rightSection :: start -->
      <b-col col sm="12" md="5" class="py-5 mt-3">
        <div class="d-flex justify-content-center align-items-center mx-auto">
          <b-list-group class="d-flex flex-column flex-sm-column-reverse">
            <router-link to="/download-zaajira-app">
              <b-list-group-item class="d-flex justify-content-between align-items-center py-4"
                style="border-radius: 10px">
                <div>
                  <p style="font-size: clamp(1rem, 5vw, 1.25rem); color: #6244e5; font-family: 'Gilroy-ExtraBold'"
                    class="mb-0">
                    <b>{{ $gettext("Find_a_Job") }}</b>
                  </p>
                  <p style="opacity: 40%; font-size: 12px; margin-bottom: 0px !important" class="d-none d-md-block mb-0">
                    <b>{{ $gettext("Candidates") }}</b>
                  </p>
                </div>
                <div class="h2 mb-0" style="color: #6244e5">
                  <b-icon-arrow-right></b-icon-arrow-right>
                </div>
              </b-list-group-item>
            </router-link>
            <span class="d-none d-sm-block" style="border-bottom: 2px solid #e3e3e3"></span>
            <span class="d-block d-md-none py-2"></span>
            <router-link to="/login">
              <b-list-group-item class="d-flex justify-content-between align-items-center py-4"
                style="border-radius: 10px">
                <div>
                  <p style="font-size: clamp(1rem, 5vw, 1.25rem); color: #6244e5; font-family: 'Gilroy-ExtraBold'"
                    class="mb-0">
                    <b>{{ $gettext("Hire_a_Candidate") }}</b>
                  </p>
                  <p style="font-size: 12px; opacity: 40%; margin-bottom: 0px !important" class="d-none d-md-block mb-0">
                    <b>{{ $gettext("Employer") }}</b>
                  </p>
                </div>
                <div class="h2 mb-0" style="color: #6244e5">
                  <b-icon-arrow-right></b-icon-arrow-right>
                </div>
              </b-list-group-item>
            </router-link>
          </b-list-group>
        </div>
      </b-col>
      <!-- rightSection :: end -->
    </b-row>
    <!-- enablingJobs :: end -->

    <!-- WhyApplyOnZaajira :: start -->
    <b-row class="py-5 my-5 mx-auto apply-sec">
      <b-col col lg="4" sm="12" xs="12" class="py-3 pr-5">
        <div class="d-flex flex-column pr-5">
          <div>
            <h2 class="text-content">{{ $gettext("Why_Apply_On_Zaajira") }}</h2>
            <p class="fz-22 fw-500 color-theam-dark">{{ $gettext("Feature_that_supercharge_your_job_search_and_profile")
            }}
            </p>
          </div>
          <div class="d-flex justify-content-between align-items-center my-3 d-block d-md-none apply-sec-dir">
            <div class="mobile-view">1 / 3</div>
            <div class="d-flex justify-content-around">
              <b-button class="customCaouselBtns" size="md" pill @click="showPrevOne">
                <!-- <img src="../../assets/app-icons/arrow-left.svg"/> -->
                <b-icon-chevron-left class="text-dark" />
              </b-button>
              <b-button class="customCaouselBtns" size="md" pill @click="showNextOne">
                <!-- <img src="../../assets/app-icons/arrow-right.svg"/> -->
                <b-icon-chevron-right class="text-dark" />
              </b-button>
            </div>
            <!-- <div style="width: 30px" class="desktop-view">1 / 3</div> -->
          </div>
        </div>
      </b-col>
      <!-- only small screen -->
      <b-col col lg="9" sm="12" xs="12" class="mx-auto d-block d-md-none">
        <VueSlickCarousel v-bind="settings" ref="carouselOne">
          <!-- <div class="p-3" v-for="i in 3" :key="i.id"> -->
          <div class="p-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="customShadow px-3 py-2 mx-auto">
                <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
                  width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path fill-rule="evenodd"
                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                </svg>
                <p class="pt-2" style="font-weight: bold">{{ $gettext("Connect_with_top_employers_in_minutes") }}</p>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="customShadow px-3 py-2 mx-auto">
                <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
                  width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path fill-rule="evenodd"
                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                </svg>
                <p class="pt-2" style="font-weight: bold">{{ $gettext("Increase_your_chances_with_custom_cards") }}</p>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="customShadow px-3 py-2 mx-auto">
                <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
                  width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path fill-rule="evenodd"
                    d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                </svg>
                <p class="pt-2" style="font-weight: bold">{{ $gettext("Browse_specific_jobs_with_detailed_requirements")
                }}</p>
              </div>
            </div>
          </div>
          <!-- </div> -->
        </VueSlickCarousel>
      </b-col>
      <!-- above small screen -->
      <b-col col lg="8" sm="12" xs="12" class="py-3 mx-auto d-none d-md-block">
        <div class="d-flex justify-content-around p-4 py-5" style="background-color: #f4f2ff">
          <div class="px-4" style="border-right: 1px solid #979797">
            <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
              width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
            </svg>
            <p class="pt-2" style="font-size: 1.2rem; font-weight: 600; color: #000000">{{
              $gettext("Connect_with_top_employers_in_minutes") }}</p>
          </div>

          <div class="px-4" style="border-right: 1px solid #979797">
            <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
              width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
            </svg>
            <p class="pt-2" style="font-weight: 600; font-size: 1.2rem; color: #000000">{{
              $gettext("Increase_your_chances_with_custom_cards") }}</p>
          </div>

          <div class="pl-4">
            <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208, 198, 255, 1); color: purple"
              width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path fill-rule="evenodd"
                d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
            </svg>
            <p class="pt-2" style="font-weight: 600; font-size: 1.2rem; color: #000000">{{
              $gettext("Browse_specific_jobs_with_detailed_requirements") }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- WhyApplyOnZaajira :: end -->

    <!-- userConnectedJobs :: start -->
    <b-row class="jobs">
      <b-col col md="12">
        <!-- for mobile only -->
        <div class="d-block d-sm-none bg-primary py-2">
          <VueSlickCarousel class="d-block d-sm-none" v-bind="settings">
            <!-- <div class="p-3" v-for="i in 3" :key="i.id"> -->
            <div class="p-3">
              <div class="d-flex justify-content-between align-items-left">
                <div class="d-flex flex-column align-self-center">
                  <h1 class="text-white fz-48 fw-600">10k+</h1>
                  <p class="text-white">{{ $gettext("Candidates") }}</p>
                </div>
                <div class="d-flex flex-column align-self-center">
                  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Group 7</title>
                    <g id="Job-application" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Job-Details" transform="translate(-8.000000, -448.000000)" fill="#ffffff"
                        fill-rule="nonzero">
                        <g id="Group-9" transform="translate(0.000000, 161.000000)">
                          <g id="Group-7" transform="translate(8.000000, 287.000000)">
                            <g id="np_language_2053716_000000" transform="translate(4.000000, 5.000000)">
                              <path
                                d="M7.9999712,2.36840591 C6.93951615,2.36840591 5.92183718,2.78435483 5.17191188,3.52449115 C4.42198658,4.26462747 4.00044638,5.2689642 4.00044638,6.31542067 C4.00044638,7.36269187 4.42192898,8.36627071 5.17191188,9.10710808 C5.92187558,9.84722545 6.93959295,10.2631933 7.9999712,10.2631933 C9.06034945,10.2631933 10.0781052,9.8472444 10.8280305,9.10710808 C11.5779942,8.36623282 11.999496,7.36263502 11.999496,6.31542067 C11.9987461,5.26888841 11.5765159,4.2660864 10.826533,3.52581745 C10.077318,2.78570008 9.06034945,2.36973221 7.99981761,2.36825433 L7.9999712,2.36840591 Z M11.1596027,5.92101477 L10.3766354,5.92101477 C10.345137,5.15276073 10.1501411,4.40006239 9.80439976,3.71100785 C10.5551314,4.22317089 11.0486115,5.02695101 11.1596027,5.92101477 Z M7.60024143,3.28696845 L7.60024143,5.92101477 L6.42203698,5.92101477 C6.51203377,4.58879592 7.03027132,3.61115586 7.60024143,3.28696845 Z M7.60024143,6.71073603 L7.60024143,9.34478235 C7.0310201,9.01987495 6.51202417,8.04289804 6.42278575,6.71073603 L7.60024143,6.71073603 Z M8.40046894,9.34478235 L8.39971883,6.71073603 L9.57717451,6.71073603 C9.48792841,8.04295488 8.96968894,9.02002653 8.39971883,9.34478235 L8.40046894,9.34478235 Z M8.39971883,5.92101477 L8.39971883,3.28696845 C8.96894016,3.61187585 9.48793609,4.58885276 9.57717451,5.92101477 L8.39971883,5.92101477 Z M6.19563729,3.71100785 C5.849896,4.40006239 5.65490774,5.15277968 5.62340166,5.92101477 L4.84043434,5.92101477 C4.95142943,5.02693206 5.44490562,4.22317089 6.19563729,3.71100785 Z M4.84043434,6.71073603 L5.62340166,6.71073603 C5.65490006,7.47825113 5.849896,8.23168841 6.19563729,8.91998506 C5.44490562,8.40856097 4.95142559,7.6040419 4.84043434,6.71073603 L4.84043434,6.71073603 Z M9.80441762,8.91998506 C10.1501589,8.23166947 10.3451472,7.47821323 10.3766532,6.71073603 L11.1596206,6.71073603 C11.0486255,7.60406085 10.5551493,8.40857991 9.80441762,8.91998506 Z"
                                id="Shape"></path>
                              <path
                                d="M13.9997576,0 L2.00018479,0 C0.896224094,0.00148015896 0.00153594532,0.884457502 0,1.97392422 L0,10.6578266 C0.0015000426,11.7472933 0.896224094,12.630235 2.00018479,12.6317508 L5.60005664,12.6317508 L5.60005664,14.605675 L5.60005664,14.6049348 C5.60005664,14.7470391 5.67730317,14.8787793 5.8033006,14.9483536 C5.92854542,15.0186649 6.08228587,15.0171852 6.20604466,14.9439129 L10.1111856,12.6317804 L13.9998152,12.6317804 C15.1037759,12.6303 15.9984641,11.7473229 16,10.6578561 L16,1.97395378 C15.9985,0.88448706 15.1037759,0.00154533749 13.9998152,0 L13.9997576,0 Z M15.1997149,10.6578266 C15.198965,11.311355 14.6619804,11.8412906 13.9997576,11.8420295 L10.000156,11.8420295 C9.92740786,11.8420295 9.85616112,11.8612724 9.79391694,11.8982801 L6.40024575,13.9076357 L6.40024575,12.2364316 C6.40024575,12.132074 6.35824725,12.0314224 6.28324896,11.9573955 C6.20825067,11.8833686 6.1062543,11.8419348 5.99975185,11.8419348 L2.00015023,11.8419348 C1.33792741,11.8411947 0.800941726,11.3112603 0.800192953,10.6577318 L0.800192953,1.97382948 C0.800942878,1.31956209 1.33792741,0.790365472 2.00015023,0.78962653 L13.999723,0.78962653 C14.6619459,0.790366609 15.1989316,1.31956209 15.1997149,1.97382948 L15.1997149,10.6578266 Z"
                                id="Shape"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="d-flex justify-content-between align-items-left">
                <div class="d-flex flex-column align-self-center">
                  <h1 class="text-white fz-48 fw-600">100+</h1>
                  <p class="text-white">{{ $gettext("Jobs1") }}</p>
                </div>
                <div class="d-flex flex-column align-self-center">
                  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Group 7</title>
                    <g id="Job-application" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Job-Details" transform="translate(-8.000000, -448.000000)" fill="#ffffff"
                        fill-rule="nonzero">
                        <g id="Group-9" transform="translate(0.000000, 161.000000)">
                          <g id="Group-7" transform="translate(8.000000, 287.000000)">
                            <g id="np_language_2053716_000000" transform="translate(4.000000, 5.000000)">
                              <path
                                d="M7.9999712,2.36840591 C6.93951615,2.36840591 5.92183718,2.78435483 5.17191188,3.52449115 C4.42198658,4.26462747 4.00044638,5.2689642 4.00044638,6.31542067 C4.00044638,7.36269187 4.42192898,8.36627071 5.17191188,9.10710808 C5.92187558,9.84722545 6.93959295,10.2631933 7.9999712,10.2631933 C9.06034945,10.2631933 10.0781052,9.8472444 10.8280305,9.10710808 C11.5779942,8.36623282 11.999496,7.36263502 11.999496,6.31542067 C11.9987461,5.26888841 11.5765159,4.2660864 10.826533,3.52581745 C10.077318,2.78570008 9.06034945,2.36973221 7.99981761,2.36825433 L7.9999712,2.36840591 Z M11.1596027,5.92101477 L10.3766354,5.92101477 C10.345137,5.15276073 10.1501411,4.40006239 9.80439976,3.71100785 C10.5551314,4.22317089 11.0486115,5.02695101 11.1596027,5.92101477 Z M7.60024143,3.28696845 L7.60024143,5.92101477 L6.42203698,5.92101477 C6.51203377,4.58879592 7.03027132,3.61115586 7.60024143,3.28696845 Z M7.60024143,6.71073603 L7.60024143,9.34478235 C7.0310201,9.01987495 6.51202417,8.04289804 6.42278575,6.71073603 L7.60024143,6.71073603 Z M8.40046894,9.34478235 L8.39971883,6.71073603 L9.57717451,6.71073603 C9.48792841,8.04295488 8.96968894,9.02002653 8.39971883,9.34478235 L8.40046894,9.34478235 Z M8.39971883,5.92101477 L8.39971883,3.28696845 C8.96894016,3.61187585 9.48793609,4.58885276 9.57717451,5.92101477 L8.39971883,5.92101477 Z M6.19563729,3.71100785 C5.849896,4.40006239 5.65490774,5.15277968 5.62340166,5.92101477 L4.84043434,5.92101477 C4.95142943,5.02693206 5.44490562,4.22317089 6.19563729,3.71100785 Z M4.84043434,6.71073603 L5.62340166,6.71073603 C5.65490006,7.47825113 5.849896,8.23168841 6.19563729,8.91998506 C5.44490562,8.40856097 4.95142559,7.6040419 4.84043434,6.71073603 L4.84043434,6.71073603 Z M9.80441762,8.91998506 C10.1501589,8.23166947 10.3451472,7.47821323 10.3766532,6.71073603 L11.1596206,6.71073603 C11.0486255,7.60406085 10.5551493,8.40857991 9.80441762,8.91998506 Z"
                                id="Shape"></path>
                              <path
                                d="M13.9997576,0 L2.00018479,0 C0.896224094,0.00148015896 0.00153594532,0.884457502 0,1.97392422 L0,10.6578266 C0.0015000426,11.7472933 0.896224094,12.630235 2.00018479,12.6317508 L5.60005664,12.6317508 L5.60005664,14.605675 L5.60005664,14.6049348 C5.60005664,14.7470391 5.67730317,14.8787793 5.8033006,14.9483536 C5.92854542,15.0186649 6.08228587,15.0171852 6.20604466,14.9439129 L10.1111856,12.6317804 L13.9998152,12.6317804 C15.1037759,12.6303 15.9984641,11.7473229 16,10.6578561 L16,1.97395378 C15.9985,0.88448706 15.1037759,0.00154533749 13.9998152,0 L13.9997576,0 Z M15.1997149,10.6578266 C15.198965,11.311355 14.6619804,11.8412906 13.9997576,11.8420295 L10.000156,11.8420295 C9.92740786,11.8420295 9.85616112,11.8612724 9.79391694,11.8982801 L6.40024575,13.9076357 L6.40024575,12.2364316 C6.40024575,12.132074 6.35824725,12.0314224 6.28324896,11.9573955 C6.20825067,11.8833686 6.1062543,11.8419348 5.99975185,11.8419348 L2.00015023,11.8419348 C1.33792741,11.8411947 0.800941726,11.3112603 0.800192953,10.6577318 L0.800192953,1.97382948 C0.800942878,1.31956209 1.33792741,0.790365472 2.00015023,0.78962653 L13.999723,0.78962653 C14.6619459,0.790366609 15.1989316,1.31956209 15.1997149,1.97382948 L15.1997149,10.6578266 Z"
                                id="Shape"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="d-flex justify-content-between align-items-left">
                <div class="d-flex flex-column align-self-center">
                  <h1 class="text-white fz-48 fw-600">100+</h1>
                  <p class="text-white">{{ $gettext("Employers") }}</p>
                </div>
                <div class="d-flex flex-column align-self-center">
                  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Group 7</title>
                    <g id="Job-application" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Job-Details" transform="translate(-8.000000, -448.000000)" fill="#ffffff"
                        fill-rule="nonzero">
                        <g id="Group-9" transform="translate(0.000000, 161.000000)">
                          <g id="Group-7" transform="translate(8.000000, 287.000000)">
                            <g id="np_language_2053716_000000" transform="translate(4.000000, 5.000000)">
                              <path
                                d="M7.9999712,2.36840591 C6.93951615,2.36840591 5.92183718,2.78435483 5.17191188,3.52449115 C4.42198658,4.26462747 4.00044638,5.2689642 4.00044638,6.31542067 C4.00044638,7.36269187 4.42192898,8.36627071 5.17191188,9.10710808 C5.92187558,9.84722545 6.93959295,10.2631933 7.9999712,10.2631933 C9.06034945,10.2631933 10.0781052,9.8472444 10.8280305,9.10710808 C11.5779942,8.36623282 11.999496,7.36263502 11.999496,6.31542067 C11.9987461,5.26888841 11.5765159,4.2660864 10.826533,3.52581745 C10.077318,2.78570008 9.06034945,2.36973221 7.99981761,2.36825433 L7.9999712,2.36840591 Z M11.1596027,5.92101477 L10.3766354,5.92101477 C10.345137,5.15276073 10.1501411,4.40006239 9.80439976,3.71100785 C10.5551314,4.22317089 11.0486115,5.02695101 11.1596027,5.92101477 Z M7.60024143,3.28696845 L7.60024143,5.92101477 L6.42203698,5.92101477 C6.51203377,4.58879592 7.03027132,3.61115586 7.60024143,3.28696845 Z M7.60024143,6.71073603 L7.60024143,9.34478235 C7.0310201,9.01987495 6.51202417,8.04289804 6.42278575,6.71073603 L7.60024143,6.71073603 Z M8.40046894,9.34478235 L8.39971883,6.71073603 L9.57717451,6.71073603 C9.48792841,8.04295488 8.96968894,9.02002653 8.39971883,9.34478235 L8.40046894,9.34478235 Z M8.39971883,5.92101477 L8.39971883,3.28696845 C8.96894016,3.61187585 9.48793609,4.58885276 9.57717451,5.92101477 L8.39971883,5.92101477 Z M6.19563729,3.71100785 C5.849896,4.40006239 5.65490774,5.15277968 5.62340166,5.92101477 L4.84043434,5.92101477 C4.95142943,5.02693206 5.44490562,4.22317089 6.19563729,3.71100785 Z M4.84043434,6.71073603 L5.62340166,6.71073603 C5.65490006,7.47825113 5.849896,8.23168841 6.19563729,8.91998506 C5.44490562,8.40856097 4.95142559,7.6040419 4.84043434,6.71073603 L4.84043434,6.71073603 Z M9.80441762,8.91998506 C10.1501589,8.23166947 10.3451472,7.47821323 10.3766532,6.71073603 L11.1596206,6.71073603 C11.0486255,7.60406085 10.5551493,8.40857991 9.80441762,8.91998506 Z"
                                id="Shape"></path>
                              <path
                                d="M13.9997576,0 L2.00018479,0 C0.896224094,0.00148015896 0.00153594532,0.884457502 0,1.97392422 L0,10.6578266 C0.0015000426,11.7472933 0.896224094,12.630235 2.00018479,12.6317508 L5.60005664,12.6317508 L5.60005664,14.605675 L5.60005664,14.6049348 C5.60005664,14.7470391 5.67730317,14.8787793 5.8033006,14.9483536 C5.92854542,15.0186649 6.08228587,15.0171852 6.20604466,14.9439129 L10.1111856,12.6317804 L13.9998152,12.6317804 C15.1037759,12.6303 15.9984641,11.7473229 16,10.6578561 L16,1.97395378 C15.9985,0.88448706 15.1037759,0.00154533749 13.9998152,0 L13.9997576,0 Z M15.1997149,10.6578266 C15.198965,11.311355 14.6619804,11.8412906 13.9997576,11.8420295 L10.000156,11.8420295 C9.92740786,11.8420295 9.85616112,11.8612724 9.79391694,11.8982801 L6.40024575,13.9076357 L6.40024575,12.2364316 C6.40024575,12.132074 6.35824725,12.0314224 6.28324896,11.9573955 C6.20825067,11.8833686 6.1062543,11.8419348 5.99975185,11.8419348 L2.00015023,11.8419348 C1.33792741,11.8411947 0.800941726,11.3112603 0.800192953,10.6577318 L0.800192953,1.97382948 C0.800942878,1.31956209 1.33792741,0.790365472 2.00015023,0.78962653 L13.999723,0.78962653 C14.6619459,0.790366609 15.1989316,1.31956209 15.1997149,1.97382948 L15.1997149,10.6578266 Z"
                                id="Shape"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </VueSlickCarousel>
        </div>
        <!-- for desktop only -->
        <div class="d-none d-md-block">
          <b-row class="d-flex justify-content-between px-4 py-5 bg-primary">
            <b-col class="p-4 text-center" cols="12" lg="3" sm="12">
              <h1 class="text-white fz-48 fw-600">10k+</h1>
              <h2 style="font-size: 1.2rem" class="text-white">{{ $gettext("Candidates") }}</h2>
            </b-col>
            <b-col class="p-4 text-center" cols="12" lg="3" sm="12">
              <h1 class="text-white fz-48 fw-600">100+</h1>
              <h2 style="font-size: 1.2rem" class="text-white">{{ $gettext("Jobs1") }}</h2>
            </b-col>
            <b-col class="p-4 text-center" cols="12" lg="3" sm="12">
              <h1 class="text-white fz-48 fw-600">100+</h1>
              <h2 style="font-size: 1.2rem" class="text-white">{{ $gettext("Employers") }}</h2>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <!-- userConnectedJobs :: end -->

    <!-- AuthFooter :: start -->
    <b-row class="mobile-view">
      <AuthFooter />
    </b-row>
    <!-- AuthFooter :: end -->
    <!-- Zaajira Pricing :: Start -->
    <!-- <b-row>
      <Pricing />
    </b-row> -->
    <!-- Zaajira Pricing :: end -->
    <!-- ZaajiraSuccessStories :: start -->
    <b-row class="px-2 py-5 mx-auto">
      <!-- left -->
      <b-col col lg="3" sm="12" xs="12" class="success-stories py-2">
        <div class="d-flex flex-column">
          <h2 class="text-content">{{ $gettext("Zaajira_Success_Stories") }}</h2>
          <!-- <div class="d-flex justify-content-between align-items-center my-3 success-stories-dir">
            <div style="opacity: 40%; font-size: 18px">1/ 4</div>
            <div class="d-flex justify-content-around">
              <b-button class="customCaouselBtns" size="md" pill @click="showPrevTwo">
                <b-icon-chevron-left class="text-dark" />
                <img src="../../assets/app-icons/arrow-left.svg" />
              </b-button>
              <b-button class="customCaouselBtns" size="md" pill @click="showNextTwo"><b-icon-chevron-right
                  class="text-dark" /></b-button>
            </div>
          </div> -->
        </div>
      </b-col>
      <!-- right -->
      <b-col col lg="9" sm="12" xs="12" class="slider-job py-2">
        <VueSlickCarousel v-bind="settings" ref="carouselTwo" class="h">
          <!-- <div v-for="n in 10" :key="n.id"> -->
          <div v-for="n in testimonials" :key="n.id">
            <!-- {{ n }} -->
            <div class="m-4 mycard">
              <div class="p-2 d-flex align-items-center mx-auto bg-blue">
                <div class="pl-1">
                  <b-avatar variant="dark"></b-avatar>
                </div>
                <div class="d-flex flex-column align-items-start ml-4">
                  <span><b>{{ n.name }}</b></span>
                  <!-- <span class="bg-link-color">{{ $gettext({ n.designation }) }}</span> -->
                  <span class="bg-link-color">{{ n.designation }}</span>
                </div>
              </div>
              <p class="client-space">{{ n.comment }}</p>
            </div>
            <!-- <b-card class="mx-2">
              <b-card-body class="d-flex justify-content-around align-items-center" style="background-color: #6244e5; color: white; border-radius: 10px;">
                <div class="p-1">
                  <svg style="border-radius: 8px; opacity: 1; background-color: rgba(208,198,255, 1); color: purple;" width="3rem" height="3rem" viewBox="0 0 16 16" class="bi bi-check-circle p-2" fill="#6244e5" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path fill-rule="evenodd" d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.236.236 0 0 1 .02-.022z" />
                  </svg>
                </div>
                <div class="d-flex flex-column align-items-start justify-content-center">
                  <span>Alan Mati</span>
                  <span style="font-size: 15px;">Custom Care Executive</span>
                </div>
                <div>img</div>
              </b-card-body>
              <b-card-text class="p-3">
                My experience with zaajira was so efficient and always rewarding, I will always be greatful to them for helping me find this opportunity.
              </b-card-text>
            </b-card> -->
          </div>
        </VueSlickCarousel>
      </b-col>
    </b-row>
    <!-- ZaajiraSuccessStories :: end -->
    <!-- AuthFooter :: start -->
    <b-row class="desktop-view">
      <AuthFooter />
    </b-row>
    <!-- AuthFooter :: end -->

    <!-- DownloadNow :: start -->
    <!-- <b-row class="bg-primary pt-4 d-flex align-items-baseline d-block d-md-none">
      <b-col col md="12" class="mx-auto">
        <h6 class="text-white fw-600 fz-40">{{ $gettext("DOWNLOAD NOW") }}</h6>
      </b-col>
      <b-col col md="4" class="mx-auto">
        <a href="https://play.google.com/store/apps/details?id=com.zaajira" target="_blank" rel="noopener noreferrer">
          <img class="img-fluid" width="150" src="@/assets/google.png" alt="google-play" />
        </a>
      </b-col>
      <b-col col md="4" class="mx-auto">
        <a href="https://apps.apple.com/app/zaajira/id6449016993?platform=iphone" target="_blank"
          rel="noopener noreferrer">
          <img class="img-fluid" width="150" src="@/assets/apple.png" alt="google-play" />
        </a>
      </b-col>
    </b-row> -->
    <!-- DownloadNow :: end -->
  </b-container>
</template>
<script>
import { BRow, BCol, BContainer } from "bootstrap-vue";
import UserService from "@/apiServices/UserService";
import CompanyService from "@/apiServices/CompanyServices";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";

import GoogleLogin from "vue-google-login";

import { socialLoginClientId, reCaptchaSiteKey } from "@/config";

import VueRecaptcha from "vue-recaptcha";
import NavbarCompNew from "../components/shared/NavbarCompNew.vue";
import AuthFooter from "../components/shared/AuthFooter.vue";

import VueSlickCarousel from "vue-slick-carousel";

import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Pricing from "./Pricing.vue";
import MainHeader from "@/views/partials/MainHeader.vue";

export default {
  components: { MainHeader, BRow, BCol, BContainer, GoogleLogin, VueRecaptcha, NavbarCompNew, AuthFooter, VueSlickCarousel, Pricing },
  data() {
    return {
      testimonials: [
        {
          name: "Shakil Vohra",
          designation: "Showroom Manager",
          comment: "The matching of candidates to jobs is very accurate we were able to close job vacancies very quickly."
        },
        {
          name: "Ally Hassan",
          designation: "Bartender",
          comment: "Very easy to use platform, allowing candidates and employers to get connected in an efficient process."
        },
        {
          name: "Zuhura Abeidi",
          designation: "IT & Mobile Sales",
          comment: "We found the right showroom sales people within a couple of days, very fast matching."
        },
      ],
      settings: {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      captchaValue: null,
      reCaptchaSiteKey: reCaptchaSiteKey,
      params: {
        client_id: socialLoginClientId,
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      //loginAttempt: true,
      fEmail: null,
      phoneOrEmail: null,
      nameState: null,
      password: null,
      company_id: null,
      token: null,
      sectionSlider: {
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    };
  },
  methods: {
    showNextOne() {
      this.$refs.carouselOne.next();
    },
    showPrevOne() {
      this.$refs.carouselOne.prev();
    },
    showNextTwo() {
      this.$refs.carouselTwo.next();
    },
    showPrevTwo() {
      this.$refs.carouselTwo.prev();
    },
    captchaVerify(event) {
      console.log(`Captcha Verify Event :`, event);
      this.captchaValue = event;
    },
    captchaExpired() {
      console.log("Captcha Expired");
      this.captchaValue = null;
    },
    captchaError() {
      console.log("Captcha Error");
      this.captchaValue = null;
    },
    // validate the input
    ValidateInput() {
      this.$refs.login.validate().then((result) => {
        if (result) {
          if (this.captchaValue) this.login();
          else
            this.$toasted.show("Please verify captcha", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          return;
        } else {
          return;
        }
      });
    },
    forgotPass() {
      let loader = this.$loading.show();
      // new stuff
      const isItEmail = (emailToCheck) => /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(emailToCheck);

      if (isItEmail(this.phoneOrEmail)) {
        UserService.forgotPassword({ user_email: this.phoneOrEmail })
          .then((res) => {
            this.$toasted.show("An OTP sent to your email", {
              duration: 6000,
              type: "error",
              icon: "check",
            });
            this.$router.push({ name: "Forgot_Password" });
            loader.hide();
          })
          .catch((err) => {
            // this.$router.push({ name: "Forgot_Password" });
            loader.hide();
            this.$toasted.show("Email not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          });
      } else {
        UserService.forgotPassword({ user_mobile_number: this.phoneOrEmail })
          .then((res) => {
            this.$toasted.show("An OTP sent to your email", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            this.$router.push({ name: "Forgot_Password" });
            loader.hide();
          })
          .catch((err) => {
            // this.$router.push({ name: "Forgot_Password" });
            loader.hide();
            this.$toasted.show("Email not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          });
      }

      // new stuff end
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleForgotPass() {
      if (this.phoneOrEmail !== "" && this.phoneOrEmail !== null) {
        const payload = this.phoneOrEmail;
        this.$store.commit("SET_EMAIL", payload);
        this.forgotPass();
      } else {
        this.$toasted.show("Please enter email or phone", {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
    async onSuccess(googleUser) {
      console.log(googleUser);
      const profile = googleUser.getBasicProfile();
      try {
        const c = await googleUser.getAuthResponse();
        // This only gets the user information: id, name, imageUrl and email
        console.log(googleUser.getBasicProfile());
        console.log("profile.getId() :>> ", profile.getId());
        console.log("profile.getName :>> ", profile.getName());
        console.log("profile.getEmail() :>> ", profile.getEmail());
        console.log("c :>> ", c);

        const user = await UserService.getUser({
          email: profile.getEmail(),
        });

        if (user.data.data.length > 0) {
          const token = { token: c.id_token, role_id: 3 };
          try {
            const login = await UserService.socialLogin(token);
            console.log("login :>> ", login.data.data);
            const payload = {
              userName: login.data.data.user_username,
              userEmail: login.data.data.user_email,
              userRole: login.data.data.role_id,
              userToken: token.token,
            };

            if (payload.userRole == 3) {
              this.$store.dispatch("onSetUserSigninValues", payload);
            }

            if (payload.userRole == 2) {
              let company_id = login.data.data.company_id;
              const company = await CompanyService.GetSingleCompany({
                company_id,
              });
              console.log("company.data.data :>> ", company.data.data);
              if (company.data.data.length > 0) {
                const p = {
                  userName: company.data.data[0].company_long_name,
                  userEmail: login.data.data.user_email,
                  userRole: login.data.data.role_id,
                  userToken: c.id_token,
                };
                this.$store.dispatch("onSetUserSigninValues", p);
              }
              this.$cookies.set("admin", login.data.data.admin);
            }

            this.$cookies.set("AIAPADHASHID", c.id_token);
            this.$cookies.set("role_id", login.data.data.role_id);
            this.$cookies.set("user_id", login.data.data.user_id);
            this.$toasted.show("Login Success", {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            if (login.data.data.role_id == 3) {
              this.$router.push({ name: "Applicant-Profile" });
            } else {
              this.$cookies.set("company_id", login.data.data.company_id);
              this.$router.push({ name: "Employer-Profile" });
            }
          } catch (error) {
            this.$toasted.show("Login Failed", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
          }
        } else {
          this.$store.commit("SET_NAME", profile.getName());
          this.$store.commit("SET_EMAIL", profile.getEmail());
          this.$toasted.show("Please set your password", {
            duration: 6000,
            type: "error",
            icon: "clear",
          });
          this.$router.push({ name: "Register" });
        }
      } catch (error) {
        this.$toasted.show("There is something wrong", {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
    onFailure(failed) {
      console.log("Login Failed", failed);
      this.$toasted.show("Login Failed", {
        duration: 6000,
        type: "error",
        icon: "clear",
      });
    },
    async login() {
      try {
        if (this.phoneOrEmail && this.password) {
          // empty login details initially
          let loginDetails = {};

          // check if user entered is a valid email or phone number
          const isItEmail = (emailToCheck) => /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(emailToCheck);

          // set property accordingly
          if (isItEmail(this.phoneOrEmail)) {
            loginDetails = {
              email: this.phoneOrEmail,
              password: this.password,
              captcha: this.captchaValue,
            };
          } else {
            loginDetails = {
              mobile_number: this.phoneOrEmail,
              password: this.password,
              captcha: this.captchaValue,
            };
          }

          // login service called
          console.log("Calling UserService.login()");
          const loginUser = await UserService.Login(loginDetails);

          // lets check the response in console
          console.log("Response of UserService.login() =", loginUser);

          // if user not found
          if (loginUser.data.data.notFound) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show("User not found", {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
            return;
          }

          // if invalid password
          if (loginUser.data.data.invalidPass) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            const attempts = loginUser.data.data.attempsRemaining ? loginUser.data.data.attempsRemaining : 0;
            if (loginUser.data.data.block_login && attempts == 0) {
              // this.loginAttempt = false;
              this.$toasted.show(loginUser.data.data.msg, {
                duration: 6000,
                type: "success",
                icon: "check",
              });
            } else {
              this.$toasted.show(loginUser.data.data.msg, {
                duration: 6000,
                type: "error",
                con: "clear",
              });
            }
            return;
          }

          // if data archived
          if (loginUser.data.data && loginUser.data.data.archived) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show(loginUser.data.data.msg, {
              duration: 6000,
              type: "success",
              icon: "check",
            });
            return;
          }

          // if captcha verification failed
          if (loginUser.data.data && loginUser.data.data.captcha_failed) {
            this.$refs["recaptcha-login"] && this.$refs["recaptcha-login"].reset();
            this.$toasted.show(loginUser.data.data.msg, {
              duration: 6000,
              type: "error",
              icon: "clear",
            });
            return;
          }

          const payload = {
            userName: loginUser.data.data.user.user_username,
            userEmail: loginUser.data.data.user.user_email,
            userRole: loginUser.data.data.user.role_id,
            userToken: loginUser.data.data.token,
          };

          console.log("[check default role here] Now the payload is = ", payload);

          // check if person is candidate
          if (payload.userRole === 3) {
            console.log("THIS PERSON IS CANDIDATE");
            this.$store.dispatch("onSetUserSigninValues", payload);
          }

          // check if person is employee
          if (payload.userRole === 2) {
            console.log("THIS PERSON IS EMPLOYER");
            try {
              let company_id = loginUser.data.data.user.company_id;
              const company = await CompanyService.GetSingleCompany({
                company_id,
              });
              console.log("COMPANY DATA = ", company.data.data);
              if (company.data.data.length > 0) {
                const p = {
                  userName: company.data.data[0].company_long_name,
                  userEmail: loginUser.data.data.user.user_email,
                  userRole: loginUser.data.data.user.role_id,
                  userToken: loginUser.data.data.token,
                };
                this.$store.dispatch("onSetUserSigninValues", p);
              }
            } catch (error) {
              console.log("error from login.vue component : ", error.message);
            }
          }

          // set cookies
          this.$cookies.set("username", payload.userName);
          this.$cookies.set("AIAPADHASHID", payload.userToken);
          this.$cookies.set("role_id", payload.userRole);

          console.log("Final login Data after cookie is set = ", loginUser.data.data.user);

          console.log("now checking if user is first login ?");
          if (loginUser.data.data.user.first_login == "Y") {
            try {
              console.log("This is the first time user logging so set the login flag");
              let setLoginFlagResponse = await UserService.SetLoginFlag();
              console.log("SetLoginFlag Response : ", setLoginFlagResponse);
            } catch (error) {
              console.log(error.message);
            }
          } else {
            console.log("Not the first time login");
          }

          const employee = {
            company_id: loginUser.data.data.user.company_id,
            role_id: loginUser.data.data.user.role_id,
          };
          // if (employee.company_id && employee.role_id == 2) {
          //   console.log("employee :>> ", employee);
          //   this.$cookies.set(
          //     "company_id",
          //     loginUser.data.data.user.company_id
          //   );
          //   this.$toasted.show("Login Success", {
          //     position: "top-right",
          //     duration: 6000,
          //     icon: "check",
          //   });
          //   this.$router.push({ name: "Employer-Profile" });
          //   return;
          // }

          const { user_id, role_id, user_username, admin, first_login, company_id } = loginUser.data.data.user;

          console.log("COMPANY ID :", company_id);
          this.$cookies.set("google_login", false);

          if (company_id) {
            this.$cookies.set("company_id", company_id);
          }
          this.$cookies.set("user_id", user_id);
          this.$cookies.set("admin", admin);

          let p = { applicant_id: user_id };

          if (role_id == 3) {
            try {
              if (loginUser.data.data.user.first_login == "N") {
                const firstTimeLogin = await ApplicantProfileService.getApplicantProfile(p);
                console.log("First Time Login = ", firstTimeLogin);

                if (firstTimeLogin.data.data.user_data.length > 0) {
                  const profileImage = firstTimeLogin.data.data.user_data[0].user_job_profile_image;
                  localStorage.setItem("image", profileImage);
                  const pay = { image: profileImage };
                  console.log("pay :>> ", pay);
                  this.$store.dispatch("onSetProfileImage", pay);
                  console.log("image :>> ", profileImage);
                  console.log("loginUser.data.data.user 2 :>> ", loginUser.data.data.user);
                }
                this.$router.push({ name: "Employer-jobs" });
              } else {
                try {
                  let setLoginFlagResponse = await UserService.SetLoginFlag();
                  console.log("setLoginFlagResponse", setLoginFlagResponse);
                  if (loginUser.data.data.user.referal_id) {
                    console.log("User have referal code");
                    // this.$router.push({
                    //   name: "Applicant-Referrals",
                    //   query: { login: 1 },
                    // });
                    this.$router.push({ name: "Applicant-Profile" });
                    this.$toasted.show("Login Success!", {
                      duration: 6000,
                      icon: "check",
                    });
                  } else {
                    console.log("User do not have referal code");
                    // this.$router.push({
                    //   name: "Applicant-Settings",
                    //   query: { login: 1 },
                    // });
                    this.$router.push({ name: "Applicant-Profile" });
                    this.$toasted.show("Login Success!", {
                      duration: 6000,
                      icon: "check",
                    });
                  }
                } catch (error) {
                  console.log("error while setting flag :>> ", error);
                }
              }

              // } else {
              //   const role = loginUser.data.data.user.role_id;
              //   console.log("role_id :>> ", role);
              //   if (role === 3) {
              //     console.log(" hitting role 3:>> ", role);
              //     const applicant = await ApplicantProfileService.addApplicantProfile(
              //       {
              //         user_id: loginUser.data.data.user.user_id,
              //         role_id: loginUser.data.data.user.role_id,
              //       }
              //     );
              //     this.$toasted.show("Login Success!", {
              //       position: "top-right",
              //       duration: 6000,
              //       icon: "check",
              //     });
              //     console.log("applicant :>> ", applicant);
              //     if (applicant.data.status) {
              //       if (loginUser.data.data.user.first_login == "N")
              //         this.$router.push({ name: "Applicant-Profile" });
              //       else
              //         this.$router.push({
              //           name: "Applicant-Settings",
              //           query: { login: 1 },
              //         });
              //     }
              //   }
              // }
            } catch (error) { }
          } else if (role_id === 2) {
            console.log("user having role id of 2");
            const p = { user_id: user_id, company_long_name: user_username };
            console.log(p);
            if (admin != "N") {
              console.log("user is not admin");
              const comp = await CompanyService.IsCompanyPresent(user_id);
              console.log("res of await CompanyService.IsCompanyPresent = ", comp);
              if (loginUser.data.data.user.first_login == "Y") {
                try {
                  console.log("This is the first login");
                  let setLoginFlagResponse = await UserService.SetLoginFlag();
                  console.log("setLoginFlagResponse = ", setLoginFlagResponse);
                  this.$cookies.set("company_id", company_id);
                  this.$cookies.set("admin", "Y");
                  this.$router.push({
                    name: "Employer-onboarding-1",
                    query: { login: 1 },
                  });
                } catch (error) {
                  console.log("error while setting flag : ", error);
                }

                // CompanyService.InsertCompany(p)
                //   .then(async (res) => {
                //     console.log("1st time login :>> ", res.data.data);
                //     this.$cookies.set(
                //       "company_id",
                //       res.data.data.company_id[0]
                //     );
                //     this.$cookies.set("admin", "Y");
                //     this.$router.push({
                //       name: "Employer-settings",
                //       query: { login: 1 },
                //     });
                //   })
                //   .catch((err) => {
                //
                //   });
              } else {
                this.$cookies.set("company_id", company_id);
                this.$router.push({
                  name: "Home",
                });
                // CompanyService.InsertCompany(p)
                //   .then(async (res) => {
                //     console.log("2nd time login ::::::>> ", res.data.data);
                //     this.$cookies.set(
                //       "company_id",
                //       res.data.data.company_id[0]
                //     );
                //   })
                //   .catch((err) => {
                //
                //   });
              }
            } else {
              console.log("hitting ---");
              this.$router.push({
                name: "Employer-jobs",
              });
            }
          } else {
            console.log("You are not authorized");
            this.$toasted.show("You are not authorized", {
              duration: 6000,
              icon: "clear",
            });
            return;
          }
        } else {
          console.log("Enter Email and Password");
        }
      } catch (error) {
        const err = JSON.stringify(error);
        this.$toasted.show(err, {
          duration: 6000,
          type: "error",
          icon: "clear",
        });
      }
    },
  },
  mounted: function () {
    console.log(`homenew component is now mounted.`);
  },
};
</script>
<style scoped>
.banner-img .list-group-item {
  border: none;
}

.jobs {
  font-family: "Outfit-Regular";
}

.jobs p {
  color: #ffffff;
  opacity: 1;
}

.fz-48 {
  font-size: 48px;
}

.fz-64 {
  font-size: 68px !important;
  font-weight: 600;
}

.fz-22 {
  font-size: 22px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.color-theam-dark {
  color: #2d2d2d;
}

.text-content {
  font-weight: 600;
  color: #000000;
  font-size: 40px;
  padding-top: 20px;
}

@media screen and (max-width:992px) {
  .text-content {
    padding-top: 0px;
  }

}

.mycard {
  border: 1px solid #e3e3e3;
  border-radius: 18px;
  -moz-box-shadow: 0 3px 11px RGBA(98, 70, 228, 0.21);
  -webkit-box-shadow: 0 3px 11px RGBA(98, 70, 228, 0.21);
  box-shadow: 0 3px 11px RGBA(98, 70, 228, 0.21);
  width: 297px;
  margin: 13px 49px !important;
}

.mycard span b {
  font-size: 22px;
  font-weight: 600;
}

.mycard span {
  font-size: 15px;
  font-weight: 500;
}

.bg-blue {
  background-color: #6244e5;
  color: white;
  border-radius: 10px;
}

.bg-link-color {
  font-size: 12px;
  color: white;
}

.customCaouselBtns {
  background-color: #fff !important;
  color: #6244e5 !important;
  border-radius: 50% 50 !important;
  margin: 10px;
  -webkit-box-shadow: 0 0 10px #ccc !important;
  box-shadow: 0 0 10px #ccc !important;
  border: 1px solid #ededed !important;
  width: 64px;
  height: 64px;
}

.customShadow {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 16px 2px rgba(204, 204, 204, 0.75);
  -webkit-box-shadow: 0px 0px 16px 2px rgba(204, 204, 204, 0.75);
  -moz-box-shadow: 0px 0px 16px 2px rgba(204, 204, 204, 0.75);
}

.banner-img {
  background-image: url("../assets/banner\ background.jpg");
  background-position: left bottom;
  padding-top: 150px !important;
  padding-bottom: 110px !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-section {
  padding: 20px 25px;
}

/* .navbar
{
  border-bottom: 1px solid rgb(255 255 255 / 45%);
    background: transparent;
} */
.client-space {
  padding: 10px !important;
  font-size: 22px;
  font-weight: 500;
  min-height: 200px;
  color: #2d2d2d;
}

.banner-img .list-group {
  width: 280px;
  background: white;
  border-radius: 10px;
}

.banner-img .list-group .list-group-item {
  border-radius: 0px;
}

.apply-sec .apply-sec-dir .mobile-view,
.mobile-view {
  display: none;
}

@media only screen and (max-width: 767px) {
  .banner-img .list-group {
    background: none;
  }

  .banner-img .list-group .list-group-item {
    border-radius: 10px;
  }

  .banner-img {
    padding-top: 80px !important;
  }

  .pr-5,
  .px-5 {
    padding-right: 0rem !important;
  }

  .apply-sec .flex-column,
  .success-stories .flex-column {
    flex-direction: inherit !important;
  }

  .apply-sec .flex-column p,
  .apply-sec .apply-sec-dir .desktop-view,
  .desktop-view {
    display: block;
  }

  .apply-sec .apply-sec-dir,
  .success-stories .success-stories-dir {
    display: block !important;
    text-align: end;
  }

  .apply-sec .apply-sec-dir .mobile-view {
    display: block;
  }

  .customCaouselBtns {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
  }

  .btn .b-icon.bi {
    font-size: 90% !important;
  }

  .text-content {
    font-size: 28px;
  }

  .customShadow {
    width: 254px;
  }

  .mycard {
    width: 100%;
    margin: 8px 0px !important;
  }

  /* .client-space {
    padding: 25px 1px 0px 15px !important;
    font-size: 12px;
    font-weight: 600;
    color: #2d2d2d;
  } */
  .client-space {
    padding: 52px 25px 26px 25px !important;
    font-size: 19 px;
    font-weight: 500;
    color: #2d2d2d;
    min-height: 300px;
  }

  .mycard span b {
    font-size: 12px;
    font-weight: 600;
  }

  .mycard span {
    font-size: 10px;
    font-weight: 500;
  }

  .text-content {
    font-size: 28px;
  }
}
</style>
